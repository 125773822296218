export default {
  name: 'CardMessage',
  props: {
    theme: {
      type: String,
      default: 'sea',
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
